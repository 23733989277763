import {
  Box,
  Container,
  useMediaQuery,
  Progress,
  Image,
  CardBody,
  Card,
  Text,
  Center
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { showToast } from '../libs/toast';
import { useSearchParams } from 'react-router-dom';
import { USERS } from './people';
import Countdown from './counter';
import Carousel from './carousel';

export default function InvitationPage() {
  const [isMovil] = useMediaQuery('(max-width: 500px)', {
    fallback: true
  });
  const audioRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const [params] = useSearchParams();
  let users = params?.get('to');
  let max = params?.get('max');

  const handleVisibilityChange = () => {
    if (document.hidden && isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isPlaying]);

  if (!isMovil) {
    setTimeout(() => {
      if (isMovil === false)
        showToast({
          title: 'Acceso Solo por Móvil',
          status: 'info',
          duration: 3000
        });
    }, 500);
    return (
      <Progress mt={20} size={'xs'} isIndeterminate colorScheme="primary" />
    );
  }

  if (!users || !max) {
    showToast({
      title: 'Acceso No Autorizado',
      status: 'warning',
      duration: 3000
    });
    return (
      <Progress mt={20} size={'xs'} isIndeterminate colorScheme="primary" />
    );
  }

  return (
    <>
      <audio ref={audioRef} src="/song.mp3" />
      <Countdown />
      <Carousel />
      <Box
        w="40%"
        left="30%"
        h="75px"
        bg={'transparent'}
        cursor="pointer"
        top="11%"
        position="absolute"
        onClick={() => {
          if (!audioRef.current) return;
          if (isPlaying) {
            audioRef.current.pause();
          } else {
            audioRef.current.play();
          }
          setIsPlaying(!isPlaying);
        }}
      ></Box>
      <Box
        w="100%"
        h="75px"
        bg={'transparent'}
        cursor="pointer"
        top="33%"
        left="0"
        position="absolute"
        onClick={() => {
          window?.open('https://maps.app.goo.gl/C7LtbQoH2WRe4ntq7', '_blank');
        }}
      ></Box>
      <Box
        w="100%"
        h="75px"
        bg={'transparent'}
        cursor="pointer"
        top="45.5%"
        left="0"
        position="absolute"
        onClick={() => {
          window?.open('https://maps.app.goo.gl/ucYLs1UKXbwrJq5C7', '_blank');
        }}
      ></Box>
      <Box
        w="100%"
        bg={'#c8bfb3'}
        cursor="pointer"
        top="94.3%"
        left="0"
        position="absolute"
      >
        <Card bg="#c8bfb3" p={0}>
          <CardBody p={2}>
            <Center whiteSpace="nowrap">
              <Text
                color="#5f544a"
                fontFamily='"YAEl5AUzZYw 0", _fb_, auto'
                whiteSpace="nowrap"
              >
                <strong>Para:</strong> {users.replace(/_/g, ' ')},{' '}
                <strong>{max}</strong> Personas.
              </Text>
            </Center>
          </CardBody>
        </Card>
      </Box>
      <Box
        w="100%"
        h="75px"
        bg={'transparent'}
        cursor="pointer"
        top="95%"
        left="0"
        position="absolute"
        onClick={() => {
          showToast({
            title: 'Confirmación Enviada',
            description: 'Su confirmación ha sido registrada con éxito',
            status: 'success',
            duration: 3500
          });
          fetch(
            `https://sandbox.akienvio.com/api/invitation?users=${users}-${max}`
          ).catch(() => {});
        }}
      ></Box>
      <Container maxW="max-content" {...(isMovil && { p: 0 })}>
        <Image src="/1.png" h="auto" w="100%%" mb="4px" />
        <hr />
        <Image src="/2.png" h="auto" w="100%%" mt="4px" mb="4px" />
        <hr />
        <Image src="/3.png" h="auto" w="100%%" mt="4px" mb="4px" />
        <hr />
        <Image src="/4.png" h="auto" w="100%%" mt="4px" mb="4px" />
        <hr />
        <Image src="/5.png" h="auto" w="100%%" mt="4px" mb="4px" />
        <hr />
        <Image src="/6.png" h="auto" w="100%%" mt="4px" mb="4px" />
        <hr />
        <Image src="/7.png" h="auto" w="100%%" mt="4px" mb="4px" />
        <hr />
        <Image src="/8.png" h="auto" w="100%%" mt="4px" mb="4px" />
      </Container>
    </>
  );
}
