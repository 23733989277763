import '../../themes/default-theme/global.scss';
import { type ReactNode } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '../../themes/default-theme';
import { ToastContainer } from '../toast';
import Layout from './layout';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

interface Props {
  children: ReactNode;
}

export default function Providers({ children }: Props) {
  return (
    <ChakraProvider theme={theme}>
      <Layout>{children}</Layout>
      <FloatingWhatsApp
        phoneNumber="+528342468818"
        accountName="Denisse y Edgar"
        notification={false}
        messageDelay={0.5}
        avatar="/avatar.jpg"
        statusMessage="Estatus de la invitación"
        chatMessage="Hola ¿En qué puedo ayudarte?"
        placeholder="Escribe un mensaje..."
        chatboxClassName="whatsapp-chatbox"
        buttonClassName="whatsapp-button"
      />
      <ToastContainer />
    </ChakraProvider>
  );
}
