import { useEffect, useState } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(duration);

const Countdown = () => {
  const targetDate = dayjs.tz('2024-09-07T18:00:00', 'America/Monterrey');
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const now = dayjs().tz('America/Monterrey');
    const difference = targetDate.diff(now);

    let seconds = Math.floor(difference / 1000);

    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;

    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    let days = Math.floor(hours / 24);
    hours = hours % 24;

    return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box
      fontFamily="Helvetica, sans-serif !important"
      color="#5F544A"
      background="transparent"
      padding="10px"
      borderRadius="5px"
      textAlign="center"
      position="absolute"
      top="2.7%"
      left="50%"
      transform="translateX(-50%)"
      zIndex="1000" // Opcional, para asegurar que esté por encima de otros elementos
    >
      <VStack spacing={2}>
        <HStack spacing={4}>
          <VStack>
            <Text fontSize="30px">{timeLeft.days}</Text>
            <Text fontSize="16px">DAYS</Text>
          </VStack>
          <VStack>
            <Text fontSize="30px">{timeLeft.hours}</Text>
            <Text fontSize="16px">HOURS</Text>
          </VStack>
          <VStack>
            <Text fontSize="30px">{timeLeft.minutes}</Text>
            <Text fontSize="16px">MINUTES</Text>
          </VStack>
          <VStack>
            <Text fontSize="30px">{timeLeft.seconds}</Text>
            <Text fontSize="16px">SECONDS</Text>
          </VStack>
        </HStack>
      </VStack>
    </Box>
  );
};

export default Countdown;
