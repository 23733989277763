import { extendTheme } from '@chakra-ui/react';

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)'
};

const config = {
  breakpoints: {
    base: '0em',
    sm: '30em',
    md: '48em',
    lg: '62.5em',
    xl: '80em',
    '2xl': '96em'
  },
  colors: {
    primary: {
      50: '#FFF5F7',
      100: '#FED7E2',
      200: '#FBB6CE',
      300: '#F687B3',
      400: '#ED64A6',
      500: '#D53F8C',
      600: '#B83280',
      700: '#97266D',
      800: '#702459',
      900: '#521B41'
    }
  },
  radii: {
    none: '0',
    sm: '0.125rem',
    base: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    full: '9999px'
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: 'lg'
      }
    },
    Card: {
      baseStyle: {
        container: {
          borderRadius: 'xl'
        }
      }
    },
    Badge: {
      baseStyle: {
        borderRadius: 'xl',
        px: '0.5rem'
      }
    },
    Modal: {
      baseStyle: {
        dialog: {
          borderRadius: 'xl'
        }
      }
    },
    Menu: {
      baseStyle: {
        button: {
          borderRadius: 'xl'
        },
        list: {
          borderRadius: 'xl'
        },
        item: {
          borderRadius: 'lg'
        }
      }
    },
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              }
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles
              },
            label: {
              top: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top'
            }
          }
        }
      }
    }
  }
};

export const theme = extendTheme(config);

export const quoterTheme = extendTheme({
  ...config,
  breakpoints: {
    ...config.breakpoints,
    lg: '77.5em'
  }
});
