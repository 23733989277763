import { Box, Image, IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const images = [
  '/c-1.jpg',
  '/c-2.jpg',
  '/c-3.jpg',
  '/c-4.jpg',
  '/c-5.jpg',
  '/c-6.jpg'
];

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <IconButton
      icon={<ChevronLeftIcon />}
      position="absolute"
      left="10px"
      aria-label=""
      top="50%"
      transform="translateY(-50%)"
      zIndex="2"
      onClick={onClick}
      background="rgba(0, 0, 0, 0.5)"
      color="white"
      _hover={{ background: 'rgba(0, 0, 0, 0.7)' }}
    />
  );
};

const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <IconButton
      icon={<ChevronRightIcon />}
      position="absolute"
      right="10px"
      aria-label=""
      top="50%"
      transform="translateY(-50%)"
      zIndex="2"
      onClick={onClick}
      background="rgba(0, 0, 0, 0.5)"
      color="white"
      _hover={{ background: 'rgba(0, 0, 0, 0.7)' }}
    />
  );
};

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    cssEase: 'linear',
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    appendDots: (dots: any) => (
      <Box>
        <ul style={{ margin: '0px' }}>{dots}</ul>
      </Box>
    ),
    customPaging: (i: any) => (
      <Image
        src={`/c-${i + 1}.jpg`}
        className="identi-box"
        width="20px"
        height="20px"
        bg="transparent"
        borderColor="white"
        border="1px solid white"
        borderRadius="50%"
        display="inline-block"
        margin="0 4px"
        _active={{ bg: 'white' }}
      />
    )
  };

  return (
    <Box
      position="absolute"
      top="56%"
      left="50%"
      transform="translate(-50%, -50%)"
      width="75%"
      h="400px"
      overflow="hidden"
      borderRadius="md"
      boxShadow="lg"
    >
      <Slider {...settings}>
        {images.map((src, index) => (
          <Box key={index}>
            <Image
              src={src}
              alt={`image-${index}`}
              objectFit="cover"
              h="370px"
              w="100%"
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Carousel;
