//Load pages
import Home from '../pages';
//Layouts
import LayoutMenu from '../libs/providers';

const Element = (
  <LayoutMenu>
    <Home />
  </LayoutMenu>
);

const routes = [
  {
    path: '/',
    element: Element,
    errorElement: Element
  },
  {
    element: Element,
    errorElement: Element
  }
];

export default routes;
