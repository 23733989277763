import { type ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

interface Props {
  children: ReactNode;
}

export default function Providers({ children }: Props) {
  return <Box>{children}</Box>;
}
