import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react';

const { ToastContainer, toast } = createStandaloneToast();

const showToast = (params: UseToastOptions) => {
  if (typeof params.title === 'string') {
    toast({
      status: 'success',
      position: 'top',
      isClosable: true,
      duration: 2000,
      ...params
    });
  }
};

const SWRToast = {
  onSuccess: (data: { [key: string]: unknown | any }) => {
    if (data?.error) {
      let title = data?.error;
      const description = data?.description;

      if (Array.isArray(data?.error) || typeof data?.error === 'object')
        title = JSON.stringify(data?.error);

      showToast({ title, description, status: 'error', duration: 5000 });
    }
  },
  onError: (error: any) => showToast({ title: error, status: 'error' })
};

export { ToastContainer, showToast, SWRToast };
